import { render, staticRenderFns } from "./rates-price-history-tooltip.vue?vue&type=template&id=bb0ce0f2&"
import script from "./rates-price-history-tooltip.vue?vue&type=script&lang=ts&"
export * from "./rates-price-history-tooltip.vue?vue&type=script&lang=ts&"
import style0 from "./rates-price-history-tooltip.vue?vue&type=style&index=0&id=bb0ce0f2&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports