

import moment from 'moment';
import { Component, Vue, Prop } from 'vue-property-decorator';
import { inject } from '@/inversify';

import { PRICE_SHOWN } from '@/modules/rates/constants';
import ProviderCard from '@/modules/common/components/ui-kit/provider-card.vue';
import JsonViewer from '@/modules/common/components/ui-kit/json-viewer.vue';
import Intraday from '@/modules/common/components/intraday.vue';
import type Day from '@/modules/common/types/day.type';
import Currency from '@/modules/common/components/currency.vue';
import ScreenshotLink from '@/modules/rates/components/screenshot-link.vue';
import PriceFilter from '@/modules/common/filters/price.filter';
import HotelRooms from '@/modules/common/interfaces/hotelRooms.interface';
import ClipText from '@/modules/common/filters/clip-text.filter';
import RatesDocumentModel from '@/modules/rates/models/rates-document.model';
import RatesCompsetMainModel from '@/modules/cluster/models/rates-compset-main.model';
import LosIndicator from '@/modules/common/components/los-indicator.vue';
import { toTrendKey } from '@/modules/common/filters/to-trend-key.filter';
import CURRENT_HOTEL_GRAPH_COLOR from '@/modules/common/constants/current-hotel-graph-color.constant';

import { SettingsGeneralService } from '@/modules/settings/settings-general.service';
import type RatesService from '@/modules/rates/rates.service';
import HotelsService, { HotelsServiceS } from '@/modules/hotels/hotels.service';
import RatesCommonService, { RatesCommonServiceS } from '@/modules/common/modules/rates/rates-common.service';
import { KEY } from '@/inversify.keys'; import type DocumentFiltersService from '@/modules/document-filters/document-filters.service';
import RatesAnalysisFiltersService, { RatesAnalysisFiltersServiceS } from '@/modules/rates/rates-analysis-filters.service';
import type RatesPriceHistoryCommonService from '../../rates-price-history-common.service';
import RatesPriceHistoryModel from '../../models/rates-price-history.model';

const BOOKING_BASIC_ICON = require('@/modules/common/assets/booking-basic.svg');

export interface TableData {
    id: string;
    name: string;
    price: number | null;
    priceType?: string;
    roomName: string;
    myHotel: boolean;
    isCompset: boolean;
    providers: string[] | null;
    link?: string;
    rank?: number;
    borderColor?: string;
    isBasic?: boolean;
    isNetCalc?: boolean;
    losRestriction?: boolean | number | null;
    screenshot?: string;
    intradayValue?: number | null;
    specialIntraday?: string | null;
    specialDateTitle?: string | null;
    pax?: number[];
    analysisData?: {
        price: number | null;
        roomName?: string | null;
        isBasic?: boolean;
        losRestriction?: boolean | number | null;
    }[];
}

@Component({
    components: {
        Currency,
        ScreenshotLink,
        Intraday,
        JsonViewer,
        ProviderCard,
        LosIndicator,
    },
})
export default class RatesPriceHistoryTable extends Vue {
    @inject(KEY.RatesService) private ratesService!: RatesService;
    @inject(KEY.DocumentFiltersService) private documentFiltersService!: DocumentFiltersService;
    @inject(KEY.RatesPriceHistoryCommonService) private ratesPriceHistoryCommonService!: RatesPriceHistoryCommonService;
    @inject(KEY.SettingsGeneralService) private settingsGeneralService!: SettingsGeneralService;
    @inject(HotelsServiceS) private hotelsService!: HotelsService;
    @inject(RatesCommonServiceS) private ratesCommonService!: RatesCommonService;
    @inject(RatesAnalysisFiltersServiceS) private ratesAnalysisFiltersService!: RatesAnalysisFiltersService;

    @Prop({ type: Object, default: () => ({}) })
    hiddenGraphs!: { [k: string]: boolean };

    @Prop({ type: Boolean })
    isLoading!: boolean;

    readonly PriceFilter = PriceFilter;

    get mainRateDocument() {
        return this.ratesPriceHistoryCommonService.rateDocuments?.main as RatesDocumentModel | RatesCompsetMainModel || null;
    }

    get dayIndex() {
        return this.ratesPriceHistoryCommonService.dayIndex;
    }

    get priceShown() {
        return this.ratesPriceHistoryCommonService.localPriceShown || this.documentFiltersService.priceShown;
    }

    private get hotelColors() {
        const { chartColors } = this.settingsGeneralService;
        const { ownerHotelId } = this.ratesPriceHistoryCommonService.compset || {};
        const { hotels } = this.ratesPriceHistoryCommonService;

        if (!ownerHotelId || !hotels) {
            return {};
        }

        const colors = hotels
            .filter(h => h !== ownerHotelId)
            .reduce((acc, hotelId, index) => ({
                ...acc,
                [hotelId]: chartColors[index],
            }), {} as Record<number, string>) || {};

        if (this.ratesPriceHistoryCommonService.compset) {
            colors[this.ratesPriceHistoryCommonService.compset.ownerHotelId] = CURRENT_HOTEL_GRAPH_COLOR;
        }

        return colors;
    }

    get bookingBasicIcon() {
        return BOOKING_BASIC_ICON;
    }

    get isClusterPage() {
        return (
            false
                || this.$route.name!.includes('cluster')
                || this.$route.name!.includes('chain')
        ) && !this.$route.name!.includes('.hotel');
    }

    get isPriceTypeLowest() {
        if (!this.ratesPriceHistoryCommonService.ratesSettings?.main) {
            return false;
        }
        return this.ratesPriceHistoryCommonService.ratesSettings.main.priceType === 'lowest';
    }

    // [TODO] Don't use ratesService. To props
    get isIntradayLoading() {
        return this.ratesService.isIntradayLoading;
    }

    // [TODO] Rename, to props
    get isCheapestChannel() {
        if (this.dayIndex) return false;
        if (!this.mainRateDocument) return false;
        return this.mainRateDocument.providerName === 'cheapest';
    }

    get currency() {
        return this.ratesPriceHistoryCommonService.currency;
    }

    get currentHotelId() {
        return this.ratesPriceHistoryCommonService.compset?.ownerHotelId || null;
    }

    get historyDate() {
        const { selectedTrendDate } = this.ratesPriceHistoryCommonService;

        if (!selectedTrendDate) {
            return '---';
        }

        return moment(selectedTrendDate).format('DD.MM');
    }

    get currentScanDate() {
        const { lastScanDate } = this.ratesPriceHistoryCommonService;
        const { dayIndex } = this.ratesPriceHistoryCommonService;

        if (!lastScanDate) return null;

        const currentScanDay = new Date(lastScanDate);
        currentScanDay.setDate(currentScanDay.getDate() - dayIndex);

        return currentScanDay;
    }

    get analysisKeys() {
        if (!this.ratesPriceHistoryCommonService.rateDocuments) {
            return [];
        }

        return Object.keys(this.ratesPriceHistoryCommonService.rateDocuments)
            .filter(key => key !== 'main')
            .map(k => ClipText(this.$te(k) ? this.$tc(k) : k, 10));
    }

    // [TODO] Don't use ratesAnalysisFiltersService
    get mainKey() {
        const v = String(this.ratesAnalysisFiltersService.mainCompareTitle!);
        return ClipText(v, 10);
    }

    get day() {
        return +this.$route.params.day! as Day;
    }

    // [TODO] to props. Avoid rates/compsets services usage
    get hasIntraday() {
        if (this.isClusterPage || !!this.dayIndex || this.isCheapestChannel) {
            return false;
        }

        const { competitors } = this.documentFiltersService;
        const isSomeOfCompetitorsHasIntraday = (competitors || [])
            .some(hotelId => this.ratesService
                .isIntraday(this.day, +hotelId));

        return isSomeOfCompetitorsHasIntraday
            || this.ratesService.isIntraday(this.day);
    }

    // [TODO] remove, just get screenshot from the document
    get hasScreenshots() {
        if (this.ratesService.isAllChannelsMode || !this.dayIndex) {
            return false;
        }

        const mainScreenshot = this.ratesService.getScreenshot(this.dayIndex as Day, this.currentHotelId!);

        if (mainScreenshot) {
            return true;
        }

        return Object
            .keys(this.ratesService.getCompetitorsRooms(this.dayIndex as Day))
            .some(hotelId => !!this.ratesService.getScreenshot(this.dayIndex! as Day, +hotelId));
    }

    get tableData() {
        const { ratesSettings, hotels } = this.ratesPriceHistoryCommonService;

        if (!this.ratesPriceHistoryCommonService.rateDocuments
            || !hotels
            || !ratesSettings
            || !this.priceShown
            || (!this.currentScanDate && !this.isCheapestChannel)
        ) {
            return [] as TableData[];
        }

        let numberOfRoomsWithPrices = 0;

        // Format data for the table. Compset data (Median/High/Low is not included)
        const tableData = hotels.map(hotelId => (
            Object.keys(this.ratesPriceHistoryCommonService.documents).reduce((acc, documentKey) => {
                const document = this.ratesPriceHistoryCommonService.documents[documentKey] as RatesPriceHistoryModel | null;
                let tableTrendKey = this.currentScanDate ? toTrendKey(this.currentScanDate) : null;

                if (this.isCheapestChannel) {
                    tableTrendKey = '0';
                }

                if (tableTrendKey === null) {
                    return acc;
                }

                // Fix for tableTrendKey in diffDays compare mode.
                if (documentKey !== 'main' && this.ratesAnalysisFiltersService.comparisonKey === 'diffDays' && this.dayIndex !== 0) {
                    const diffDays = this.ratesAnalysisFiltersService.comparisonDayItems.find(item => item.name === documentKey)?.value;
                    const diffDaysDate = new Date(this.currentScanDate!);
                    diffDaysDate.setDate(diffDaysDate.getDate() - (Number(diffDays) || 0));
                    tableTrendKey = toTrendKey(diffDaysDate);
                }

                const name = this.hotelsService.getHotelName(hotelId) || String(hotelId);
                const hotelColor = this.hotelColors[hotelId] || '#000000';

                // If no hotels, then show no data
                if (!document?.trendData[tableTrendKey]?.hotels || !Object.keys(document.trendData[tableTrendKey]!.hotels).length) {
                    if (documentKey !== 'main') {
                        return acc;
                    }

                    return {
                        ...acc,
                        id: String(hotelId),
                        name,
                        price: null,
                        priceType: '',
                        roomName: '---',
                        borderColor: hotelColor,
                        myHotel: hotelId === this.ratesPriceHistoryCommonService.compset?.ownerHotelId,
                    } as TableData;
                }

                const hotel = document.trendData[tableTrendKey]!.hotels[hotelId];

                // If there are hotels, but no data for hotel with specified id, then NA
                if (!hotel || !hotel.rooms || !hotel.rooms[Number(Object.keys(hotel.rooms)[0])]) {
                    if (documentKey !== 'main') {
                        return {
                            ...acc,
                            analysisData: [
                                ...(acc.analysisData || []),
                                { price: -1 },
                            ],
                        };
                    }

                    return {
                        ...acc,
                        id: String(hotelId),
                        name,
                        price: -1,
                        priceType: '',
                        roomName: '---',
                        borderColor: hotelColor,
                        myHotel: hotelId === this.ratesPriceHistoryCommonService.compset?.ownerHotelId,
                    } as TableData;
                }

                const room = hotel.rooms[parseInt(Object.keys(hotel.rooms)[0], 10)][0];
                const price = this.ratesCommonService.switchPrice(room, this.priceShown);
                const { pax } = hotel;
                const losRestriction = price! >= 0
                    ? hotel.losRestriction
                    : null;

                if (documentKey === 'main') {
                    if (price && price > 0) {
                        numberOfRoomsWithPrices += 1;
                    }

                    return {
                        ...acc,
                        id: String(hotelId),
                        name,
                        price,
                        priceType: (price && price > 0) ? room.priceType : '',
                        roomName: (price && price > 0) ? room.roomName : '---',
                        myHotel: hotelId === this.ratesPriceHistoryCommonService.compset?.ownerHotelId,
                        isCompset: false,
                        link: !this.dayIndex ? this.ratesCommonService.getHotelLink(this.day, hotelId, this.mainRateDocument) : '',
                        borderColor: hotelColor,
                        isBasic: room.isBasic,
                        isNetCalc: room.isNetCalc && this.priceShown === PRICE_SHOWN.NET,
                        losRestriction,
                        screenshot: this.ratesCommonService.getScreenshot(this.day, hotelId, this.mainRateDocument),
                        specialIntraday: room.specialDateName,
                        pax,

                        intradayValue: this.hasIntraday
                            ? this.ratesService.intradayByHotelId(this.day, hotelId, this.priceShown)
                            : null,

                        specialDateTitle: room.specialDateName
                            ? `${this.$tc('titles.specdate')}: ${room.specialDateName}`
                            : undefined,

                        providers: this.ratesService
                            .getRoomProviders(this.day, hotelId, this.priceShown),
                    } as TableData;
                }

                return {
                    ...acc,
                    analysisData: [
                        ...(acc.analysisData || []),
                        {
                            price,
                            isBasic: room.isBasic,
                            losRestriction,
                            roomName: room.roomName,
                            pax,
                        },
                    ],
                };
            }, {} as TableData)
        ));

        // Format compset data (Median/High/Low)
        const compsetTableData = Object.keys(this.ratesPriceHistoryCommonService.documents).reduce((acc, documentKey) => {
            const { compset } = this.ratesPriceHistoryCommonService;

            if (!compset) {
                return acc;
            }

            const document = this.ratesPriceHistoryCommonService.documents[documentKey] as RatesPriceHistoryModel | null;
            let tableTrendKey = this.currentScanDate ? toTrendKey(this.currentScanDate) : null;

            if (this.isCheapestChannel) {
                tableTrendKey = '0';
            }

            if (tableTrendKey === null) {
                return acc;
            }

            // Fix for tableTrendKey in diffDays compare mode.
            if (documentKey !== 'main' && this.ratesAnalysisFiltersService.comparisonKey === 'diffDays' && this.dayIndex !== 0) {
                const diffDays = this.ratesAnalysisFiltersService.comparisonDayItems.find(item => item.name === documentKey)?.value;
                const diffDaysDate = new Date(this.currentScanDate!);
                diffDaysDate.setDate(diffDaysDate.getDate() - (Number(diffDays) || 0));
                tableTrendKey = toTrendKey(diffDaysDate);
            }

            const competitorRooms = hotels.reduce((roomsAcc, hotelId) => {
                if (hotelId === this.currentHotelId) {
                    return roomsAcc;
                }

                const hotel = document?.trendData[tableTrendKey!]?.hotels[hotelId];

                if (!hotel || !hotel.rooms || !hotel.rooms[Number(Object.keys(hotel.rooms)[0])]) {
                    return roomsAcc;
                }

                const room = hotel.rooms[parseInt(Object.keys(hotel.rooms)[0], 10)][0];
                const price = this.ratesCommonService.switchPrice(room, this.priceShown);

                if (!room || !price || price < 0) {
                    return roomsAcc;
                }

                return { ...roomsAcc, [hotelId]: room };
            }, {} as HotelRooms);

            const price = this.ratesCommonService.getCompsetPrice(competitorRooms, compset.type, this.priceShown);

            if (documentKey === 'main') {
                return {
                    ...acc,
                    id: compset.type,
                    name: String(this.$t(`compsetRate.${compset.type}`)),
                    isCompset: true,
                    price: price || -1,
                    isBasic: false,
                    myHotel: false,
                    roomName: '-',
                    rank: 0,
                };
            }

            return {
                ...acc,
                analysisData: [
                    ...(acc.analysisData || []),
                    {
                        price: price || -1,
                        isBasic: false,
                        losRestriction: false,
                        roomName: '-',
                    },
                ],
            };
        }, {} as TableData);

        const isAnyCompsetData = (compsetTableData.price || 0) > 0 || compsetTableData.analysisData?.find(a => (a?.price ? a.price > 0 : false));
        if (isAnyCompsetData) {
            tableData.push(compsetTableData);
        }

        return tableData
            .sort((a, b) => (b.price || 0) - (a.price || 0))
            .map(tableRow => {
                if (!tableRow.price || tableRow.price <= 0 || tableRow.isCompset) {
                    return tableRow;
                }

                const rank = numberOfRoomsWithPrices;
                numberOfRoomsWithPrices -= 1;

                return {
                    ...tableRow,
                    rank,
                };
            });
    }

    hasAnalysisData(hotel: TableData, docIndex = 0) {
        return hotel.analysisData?.[docIndex]
            && hotel.analysisData?.[docIndex].price
            && hotel.analysisData[docIndex].price !== -1;
    }

    isAnalysisDataSoldOut(hotel: TableData, docIndex = 0) {
        return hotel.analysisData?.[docIndex]
            && hotel.analysisData?.[docIndex].price === 0;
    }

    isAnalysisDataNa(hotel: TableData, docIndex = 0) {
        return hotel.analysisData?.[docIndex]
            && hotel.analysisData?.[docIndex].price === -1;
    }

    isMainRoomDifferent(hotel: TableData, docIndex = 0) {
        if (!hotel.analysisData) return false;

        const compareRoomName = hotel.analysisData[docIndex]?.roomName;
        const mainRoomName = hotel.roomName;

        return compareRoomName !== mainRoomName;
    }

    isValidComparePrice(hotel: TableData, docIndex = 0) {
        return hotel.analysisData?.[docIndex]
            && hotel.analysisData?.[docIndex].price
            && hotel.analysisData[docIndex].price !== -1;
    }

    toggleGraph(hotel: TableData) {
        this.$emit('toggle-hotel', hotel);
    }
}
